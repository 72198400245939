








import { Component, Prop, Vue } from 'vue-property-decorator'

import { LinkProps } from '../../../../dsl/atoms/Link'

@Component<ProfileNavItem>({
  name: 'ProfileNavItem'
})
export class ProfileNavItem extends Vue {
  @Prop({ type: Object, required: false })
  protected readonly content?: LinkProps

  @Prop({ type: Boolean, required: false })
  protected readonly isActive?: boolean
}

export default ProfileNavItem
