

































import { Component, Mixins } from 'vue-property-decorator'

import { ImageProps } from '../../../dsl/atoms/Image'
import { LogoutMixin } from '../../../front/auth/shared'
import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract/ui'

import { ProfileNavItem } from './_partials/ProfileNavItem.vue'

import { ProfileNavModule } from '../ProfileNav.contracts'
import { NavigationItem } from '../../../contexts'
import { ResolvedLink } from '../../partials'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl> (original)
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ProfileNavModuleUi>({
  name: 'ProfileNavModuleUi',
  components: { ProfileNavItem }
})
export class ProfileNavModuleUi extends Mixins<AbstractModuleUi<ProfileNavModule>>(AbstractModuleUi, LogoutMixin) {
  public get image (): ImageProps | undefined {
    if (!this.data.content.background) {
      return
    }
    return toImageProps(this.data.content.background)
  }

  public get items (): Array<NavigationItem> {
    if (!this.data.content.items) {
      return []
    }
    return this.data.content.items
  }

  public get hasCta (): boolean {
    return !!this.data.content.cta
  }

  public get caption (): string | undefined {
    return this.data.content.caption
  }

  public get cta (): ResolvedLink | null {
    return this.data.content.cta ?? null
  }

  public get title (): string {
    return this.data.content.title
  }

  public isActive (item: NavigationItem): boolean {
    return item.target
      ? (this.$route.path.includes(String(item.target)) ?? false)
      : false
  }
}
export default ProfileNavModuleUi
